@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.main-screen__top-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;

}
