.sv-btn-blue {
  cursor: pointer;
  color: var(--theme-button-colort);
  background-color: var(--theme-primary-color);
  border-color: var(--theme-primary-color);

  &:hover {
    color: var(--theme-button-colort);
    background-color: var(--theme-primary-color-hover);
    //border-color: var(--theme-primary-color-hover);
  }

  &:focus {
    color: var(--theme-button-colort);
    background-color: var(--theme-primary-color-hover);
    //border-color: var(--theme-primary-color-hover);
    //box-shadow: 0 0 0 0.2rem var(--theme-primary-color-focus);
  }

   &:disabled {
     &:hover {
       background-color: var(--theme-primary-color);
       //border-color: var(--theme-primary-color);
     }
   }
}

.sv-btn-gray {
  cursor: pointer;
  color: var(--theme-font-tertiary);
  background-color: var(--theme-button);
  border-color: var(--theme-button);

  &:hover {
    color: var(--theme-primary-color);
    background-color: var(--theme-button-hover);
    border-color: var(--theme-button-hover);
  }

  &:focus {
    color: var(--theme-primary-color);
    background-color: var(--theme-button-hover);
    border-color: var(--theme-button-hover);
    box-shadow: 0 0 0 0.2rem var(--theme-primary-color-focus);
  }

  &:disabled {
    &:hover {
      color: var(--theme-font-tertiary);
      background-color: var(--theme-button);
      border-color: var(--theme-button);
    }
  }
}

.sv-btn {
  padding: 0.75rem 3.75rem;
  .spinner-border {
    margin-right: .4rem;
  }
}

.sv-btn-sm {
  padding: 0.75rem 1.75rem;
  .spinner-border {
    margin-right: .2rem;
  }
}

.sv-btn-outline {
  background-color: transparent;

  &.sv-btn-blue {
    color: var(--theme-primary-color);

    &:hover {
      color: var(--theme-button-colort);
    }
  }
}

.sv-btn-ui {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: $border-radius-big;

  svg {
    width: 24px;
    height: 24px;
  }

  @include breakpoint($lt-md) {
    width: 40px;
    height: 40px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.sv-ui-btn-blue {
  @extend .sv-btn-blue;
  @extend .sv-btn-ui;
}

.sv-ui-btn-gray {
  @extend .sv-btn-gray;
  @extend .sv-btn-ui;

  &--active {
    color: var(--theme-primary-color);
    background-color: var(--theme-button);
    border-color: var(--theme-button);
  }

}

.sv-ui-btn-m {
  +.sv-ui-btn-m {
    margin-left: 1rem;

    @include breakpoint($lt-md) {
      margin-left: .5rem;
    }
  }
}
