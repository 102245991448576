@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.settings-menu {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;

  .settings-menu__wrap {
    max-width: 830px;
    width: 100%;
  }

  .settings-menu__item {
    max-width: 830px;
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--theme-border);
    color: var(--theme-font-primary);
    cursor: pointer;
    &:last-child {
      border-bottom: none;
    }

    &:hover {
      text-decoration: none;

      .settings-menu__item-name {
        text-decoration: underline;
      }

      .settings-menu__item-meta-info {
        text-decoration: none;
      }
    }
  }

  .settings-menu__item-type {
    display: flex;
    align-items: center;
  }

  .settings-menu__item-icon {
    width: 24px;
    height: 24px;
    margin-right: .5rem;
    color: var(--theme-font-tertiary);
  }

  .settings-menu__item-name {
    font-weight: bold;
    font-size: 1.2rem;

    @include breakpoint($lt-md) {
      font-size: 1rem;
    }
  }

  .settings-menu__item-meta {
    display: flex;
    align-items: center;
  }

  .settings-menu__item-meta-info {
    color: var(--theme-font-secondary);

    @include breakpoint($lt-md) {
      font-size: .9rem;
    }
  }

  .settings-menu__item-cheveron {
    width: 20px;
    height: 20px;
    margin-left: .5rem;
  }
}
