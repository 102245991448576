@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.edit-avatar {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  @include breakpoint($lt-md) {
    flex-direction: column;
    justify-content: start;
  }
}

.edit-avatar__input {
  display: none;
}

.edit-avatar__box {
  position: relative;
  border-radius: 50%;
  cursor: pointer;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5));
  @include breakpoint($lt-md) {
    margin-bottom: 1rem;
  }

  &:after {
    content: '';
    position: absolute;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-image: url("/static/icons/camera.svg");
    bottom: 16px;
    left: calc(50% - 16px);
    opacity: 0.6;
    transition: opacity .2s linear;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }

  &--without-camera {
    &:after {
      display: none;
    }
  }
}

.edit-avatar__content {
  margin-left: 24px;

  @include breakpoint($lt-md) {
    margin-left: 0;
    width: 100%;
  }

  .edit-avatar__btn {
    display: block;
    padding: 0.5rem 0;
    margin-top: 1rem;
    cursor: pointer;
    color: var(--theme-primary-color);

    @include breakpoint($lt-md) {
      margin-top: .5rem;
    }

    &:hover {
      text-decoration: underline;
      color: var(--theme-primary-color-hover);
    }
  }

  .edit-avatar__delete {
    color: var(--theme-error-color);

    &:hover {
      color: var(--theme-error-color-hover);
    }
  }
}

.edit-avatar__spinner {
  padding: 0;
  position: absolute;
  top: calc(50% - 50px);
  left: 0;
}
