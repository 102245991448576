@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.main-list-header {
  padding-bottom: 15px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.2rem;
  display: flex;
  margin-top: 3rem;

  @include breakpoint($lt-md) {
    display: none;
  }

  .main-list-header__icon {
    width: $list-icon-width;
    min-width: $list-icon-width;
    margin-right: $list-col-padding;
  }

  .main-list-header__wrap {
    display: flex;
    width: calc(100% - #{$list-icon-width + $list-col-padding});
    max-width: calc(100% - #{$list-icon-width + $list-col-padding});
    color: var(--theme-font-secondary);
  }

  .main-list-header__name {
    width: $list-name-col-width;
    padding-right: $list-col-padding;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .main-list-header__meta-box {
    width: $list-name-meta-box-width;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .main-list-header__meta {
    padding-right: $list-col-padding;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .main-list-header__meta-date {
    width: $list-meta-col-date;
  }

  .main-list-header__meta-size {
    width: $list-meta-col-size;
  }

  .main-list-header__sortable {
    position: relative;
    padding-right: 20px;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      width: 14px;
      height: 14px;
      top: calc(50% - 7px);
      right: 0;
      background-image: url("/static/icons/sort-arrow.svg");
      background-size: cover;
      opacity: 0;
      transition: opacity 0.1s linear;
    }

    &--asc {
      &:after {
        opacity: 1;
      }
    }

    &--desc {
      &:after {
        opacity: 1;
        transform: rotate(180deg);
      }
    }

    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }
}
