@import "variables";
@import "breakpoints";

:root {
  --theme-font-primary: #F7F7F7;
  --theme-font-secondary: rgba(235, 235, 245, 0.6);
  --theme-font-tertiary: rgba(235, 235, 245, 0.3); // Icons, tertiary text

  --theme-background: #11151C;
  --theme-modals-ui-element-bg: #2E343D;
  --theme-border: #2D3137;
  --theme-select-background: #1B2028;

  --theme-ui-element-bg: rgba(235, 235, 245, 0.16);

  --theme-button: #1B2028;
  --theme-button-hover: #22272F;

  --theme-box-shadow: rgb(0 0 0 / 25%);
  --theme-text-field: #393C43;

  --theme-button-colort: #fff;
  --theme-primary-color: #0082ff;
  --theme-primary-color-hover: #0069d9;
  --theme-primary-color-focus: rgba(38, 143, 255, 0.5);
  --theme-error-color: #D7392C;
  --theme-error-color-hover: #AD2C21;

  --theme-login-fb-btn-bg: #3A559F;  // кнопки фейсбука на логине
  --theme-login-fb-btn-bg-hover: #2A3D73; // кнопки фейсбука на логине

  --theme-login-help-text: rgba(235, 235, 245, 0.6); //аналогичен --theme-font-secondary темной теме, тк страница темная
  --theme-login-text: #F7F7F7; //аналогичен --theme-font-primary темной теме
  --theme-login-buttons: #EFEFF4; //аналогичен --theme-button в светлой теме
  --theme-login-buttons-hover: #d8d8dd; //аналогичен --theme-button в светлой теме

  --theme-viewer-ui-el: #F7F7F7; //одинаковые в обоих темах аналогичен темному --theme-font-primary
  --theme-viewer-ui-el-hover: #C6C6C8; //одинаковые в обоих темах
  --theme-sharing-bg: #11151C; // аналогичен темному бекграунду в темной теме
  --theme-sharing-font-secondary: rgba(235, 235, 245, 0.6); // аналогичен темному --theme-font-primary в темной теме

  /* BOOTSTRAP COLORS */
  --theme-bp-input-border: #ced4da;
  --theme-bp-input-border--focus: #80bdff;
  --theme-bp-input-shadow: rgba(0, 123, 255, 0.25);
  --theme-bp-input-border-warning: #dc3545;
  --theme-bp-input-shadow-warning: rgba(220, 53, 69, 0.25);
}

.light {
  --theme-font-primary: #000;
  --theme-font-secondary: rgba(60, 60, 67, 0.6);
  --theme-font-tertiary: rgba(60, 60, 67, 0.3); // Icons, tertiary text

  --theme-background: #fff;
  --theme-modals-ui-element-bg: #fff; // todo придется менять для темной темы в стилях
  --theme-border: #C6C6C8;
  --theme-select-background: #EFF7FF;
  --theme-ui-element-bg: #F9F9F9;

  --theme-button: #EFEFF4;
  --theme-button-hover: #d8d8dd;

  --theme-box-shadow: rgb(0 0 0 / 25%);
  --theme-text-field: #F9F9F9;

  --theme-button-colort: #fff;
  --theme-primary-color: #0082ff;
  --theme-primary-color-hover: #0069d9;
  --theme-primary-color-focus: rgba(38, 143, 255, 0.5);
  --theme-error-color: #D7392C;
  --theme-error-color-hover: #AD2C21;

  --theme-login-fb-btn-bg: #3A559F;  // кнопки фейсбука на логине
  --theme-login-fb-btn-bg-hover: #2A3D73; // кнопки фейсбука на логине

  --theme-login-help-text: rgba(235, 235, 245, 0.6); //аналогичен --theme-font-secondary темной теме, тк страница темная
  --theme-login-text: #F7F7F7; //аналогичен --theme-font-primary темной теме
  --theme-login-buttons: #EFEFF4; //аналогичен --theme-button в светлой теме
  --theme-login-buttons-hover: #d8d8dd; //аналогичен --theme-button в светлой теме

  --theme-viewer-ui-el: #F7F7F7; //одинаковые в обоих темах --theme-font-primary
  --theme-viewer-ui-el-hover: #C6C6C8; //одинаковые в обоих темах
  --theme-sharing-bg: #11151C; // аналогичен темному бекграунду в темной теме
  --theme-sharing-font-secondary: rgba(235, 235, 245, 0.6); // аналогичен темному --theme-font-primary в темной теме


  /* BOOTSTRAP COLORS */
  --theme-bp-input-border: #ced4da;
  --theme-bp-input-border--focus: #80bdff;
  --theme-bp-input-shadow: rgba(0, 123, 255, 0.25);
  --theme-bp-input-border-warning: #dc3545;
  --theme-bp-input-shadow-warning: rgba(220, 53, 69, 0.25);
}


