@import "../../../../styles/variables/variables";
@import "../../../../styles/variables/breakpoints";

.custom-modal-languages {
  .custom-modal-languages__search {
    background-color: var(--theme-text-field);
    border-radius: $border-radius-big;
    border: 1px solid var(--theme-text-field);
    transition: all .1s ease-in-out;
    position: relative;
    padding-left: 30px;
    margin-bottom: 1rem;

    &:after {
      content: '';
      background-image: url("/static/icons/search.svg");
      width: 20px;
      height: 20px;
      position: absolute;
      top: 14px;
      left: 15px
    }

    &--focus {
      background-color: var(--theme-modals-ui-element-bg);
    }
  }

  .custom-modal-languages__input {
    background-color: transparent;
    border: none;
    color: var(--theme-font-primary);
    font-weight: 300;

    &::placeholder {
      color: var(--theme-font-tertiary);
    }

    &:focus {
      border: none;
      background-color: transparent;
      box-shadow: none;
    }
  }

  .custom-modal-languages__box {
    height: 230px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
    }


    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 4px;
      border-radius: 4px;
      background: var(--theme-button-hover);
    }
  }

  .custom-modal-languages__item {
    padding: 10px;
    border-radius: $border-radius;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background-color: var(--theme-text-field);
    }
  }
}
