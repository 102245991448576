@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.dropdown-modal {
  position: relative;

  .dropdown-menu__box {
    display: none;
    z-index: 100;
    background: var(--theme-modals-ui-element-bg);
    box-shadow: 0 3px 10px 0 var(--theme-box-shadow);
    border-radius: $border-radius-big;
    position: absolute;
    top: 115%;
    right: 0;
    left: auto;
    min-width: 200px;
  }

  &--left {
    .dropdown-menu__box {
      left: 0;
    }
  }

  .dropdown-menu__header {
    display: none;
  }

  .dropdown-menu__footer {
    padding: 15px;
  }

  .dropdown-menu__bg {
    display: none;
  }

  .dropdown-menu__item {
    padding: 10px 15px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:last-child {
      border-bottom: none;
      padding-bottom: 15px;
    }

    &:first-child {
      padding-top: 15px;
    }

    &:hover {
      .dropdown-menu__item-icon {
        color: var(--theme-font-primary);
      }
    }
  }

  .dropdown-menu__item-icon {
    color: var(--theme-font-tertiary);
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }

  &--mobile {
    .dropdown-menu__bg {
      background-color: rgba(0, 0, 0, 0.5);
    }

    .dropdown-menu__header {
      display: flex;
      align-items: center;
      padding: 20px 15px;
      border-bottom: 1px solid var(--theme-button);
    }

    .dropdown-menu__header-close {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      color: var(--theme-font-secondary);
    }

    .dropdown-menu__header-name {
      color: var(--theme-font-secondary);
    }

    .dropdown-menu__box {
      display: block;
      position: fixed;
      bottom: -600px;
      top: auto;
      left: 0;
      right: 0;
      transition: bottom 0.2s ease-out;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      @media screen and (max-height: 450px) {
        max-height: 85vh;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        overflow: hidden;
      }
    }

    &.dropdown-modal--show {
      .dropdown-menu__box {
        bottom: 0;
      }
    }
  }

  &--show {
    .dropdown-menu__box {
      display: block;
    }

    .dropdown-menu__bg {
      display: block;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 99;
    }
  }

}
