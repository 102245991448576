body {
  background-color: var(--theme-background);
  transition: color .1s linear,
              background-color .1s linear,
              border .1s linear;

  * {
    transition: color .1s linear,
                background-color .1s linear,
                background .1s linear,
                border .1s linear,
                fill .1s linear;
  }
}

.custom-modal {


  .modal-dialog {
    @include breakpoint($gt-xs) {
      max-width: 551px + 15px;
    }
  }

  &.custom-modal--big {
    .modal-dialog {
      @include breakpoint($gt-xs) {
        max-width: 890px + 15px;
      }
    }
  }

  .modal-header {
    justify-content: flex-start;
    align-items: center;
    border: none;
    min-height: 70px;

    @include breakpoint($gt-sm) {
      padding:
        $default-padding * 2
        $default-padding * 2
        0
        $default-padding * 2;
    }

    @media screen and (max-height: 450px) {
      padding: 0.75rem 1rem;
      min-height: 50px;

      .custom-modal__icon {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }

      .custom-modal__title {
        font-size: 1.125rem;
      }
    }
  }

  .modal-body {
    @include breakpoint($gt-sm) {
      padding:
        $default-padding * 2
        $default-padding * 2 + 32px + 15px
        $default-padding * 2
        $default-padding * 2 + 32px + 15px;
    }
  }

  .custom-modal__icon {
    color: var(--theme-primary-color);
    width: 32px;
    height: 32px;
    margin-right: 15px;

    &--red {
      color: var(--theme-bp-input-border-warning);
    }

    @include breakpoint($lt-md) {
      width: 28px;
      height: 28px;
    }
  }

  .custom-modal__title {
    font-weight: bold;
    font-size: 1.7rem;
    max-width: calc(100% - 28px - 15px - 40px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include breakpoint($lt-md) {
      font-size: 1.35rem;
    }
  }

  .custom-modal__btn-box {
    display: flex;
    justify-content: center;

    @include breakpoint($gt-sm) {
      width: 100%;
    }
  }

  .close {
    margin-top: -1.3rem;
  }

  .custom-modal__btn-margin {
    margin-top: 40px;

    @include breakpoint($lt-md) {
      margin-top: 20px;
    }

    @media screen and (max-height: 450px) {
      margin-top: 20px;
    }
  }

  dd {
    word-wrap: break-word;
  }
}

.custom-spinner {
  width: 1.2rem;
  height: 1.2rem;
  border-width: 0.15rem;
  border-color: var(--theme-primary-color);
  border-right-color: transparent;
}

.without-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}
