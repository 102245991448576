@charset "UTF-8";
/** Размеры экранов для адаптива **/
/** В первую очередь используются для breakpoints **/
/** Меньше чем **/
/** Больше чем **/
/** Фиксированный брейкпоинт **/
body:before {
  opacity: 0;
  position: absolute;
  z-index: -9999;
}
@media screen and (min-width: 0) and (max-width: 575px) {
  body:before {
    content: "xs";
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  body:before {
    content: "sm";
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  body:before {
    content: "md";
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  body:before {
    content: "lg";
  }
}
@media screen and (min-width: 1200px) and (max-width: 9999px) {
  body:before {
    content: "xl";
  }
}

.modal-content {
  background-color: var(--theme-modals-ui-element-bg);
  color: var(--theme-font-primary);
}

.modal-header .close {
  color: var(--theme-font-tertiary);
  text-shadow: none;
}
.modal-header .close:focus {
  outline: none;
}

.row.custom-row {
  margin-right: -8px;
  margin-left: -8px;
}
.row.custom-row .col-1, .row.custom-row .col-2, .row.custom-row .col-3, .row.custom-row .col-4, .row.custom-row .col-5, .row.custom-row .col-6, .row.custom-row .col-7, .row.custom-row .col-8,
.row.custom-row .col-9, .row.custom-row .col-10, .row.custom-row .col-11, .row.custom-row .col-12, .row.custom-row .col, .row.custom-row .col-auto,
.row.custom-row .col-sm-1, .row.custom-row .col-sm-2, .row.custom-row .col-sm-3, .row.custom-row .col-sm-4, .row.custom-row .col-sm-5,
.row.custom-row .col-sm-6, .row.custom-row .col-sm-7, .row.custom-row .col-sm-8, .row.custom-row .col-sm-9, .row.custom-row .col-sm-10,
.row.custom-row .col-sm-11, .row.custom-row .col-sm-12, .row.custom-row .col-sm, .row.custom-row .col-sm-auto, .row.custom-row .col-md-1,
.row.custom-row .col-md-2, .row.custom-row .col-md-3, .row.custom-row .col-md-4, .row.custom-row .col-md-5, .row.custom-row .col-md-6, .row.custom-row .col-md-7,
.row.custom-row .col-md-8, .row.custom-row .col-md-9, .row.custom-row .col-md-10, .row.custom-row .col-md-11, .row.custom-row .col-md-12, .row.custom-row .col-md,
.row.custom-row .col-md-auto, .row.custom-row .col-lg-1, .row.custom-row .col-lg-2, .row.custom-row .col-lg-3, .row.custom-row .col-lg-4, .row.custom-row .col-lg-5,
.row.custom-row .col-lg-6, .row.custom-row .col-lg-7, .row.custom-row .col-lg-8, .row.custom-row .col-lg-9, .row.custom-row .col-lg-10, .row.custom-row .col-lg-11,
.row.custom-row .col-lg-12, .row.custom-row .col-lg, .row.custom-row .col-lg-auto, .row.custom-row .col-xl-1, .row.custom-row .col-xl-2, .row.custom-row .col-xl-3,
.row.custom-row .col-xl-4, .row.custom-row .col-xl-5, .row.custom-row .col-xl-6, .row.custom-row .col-xl-7, .row.custom-row .col-xl-8, .row.custom-row .col-xl-9,
.row.custom-row .col-xl-10, .row.custom-row .col-xl-11, .row.custom-row .col-xl-12, .row.custom-row .col-xl, .row.custom-row .col-xl-auto {
  padding-left: 8px;
  padding-right: 8px;
}

.form-control {
  background-color: var(--theme-text-field);
  border-color: var(--theme-border);
  color: var(--theme-font-primary);
  transition: background-color 0.1s linear, border-color 0.1s linear, box-shadow 0.1s linear;
}
.form-control:focus {
  background-color: var(--theme-text-field);
  border-color: var(--theme-text-field);
  color: var(--theme-font-primary);
}
.form-control.is-invalid {
  background-image: none;
}

.form-text:after {
  content: "-";
  opacity: 0;
  margin-right: -5px;
}

body {
  background-color: var(--theme-background);
  transition: color 0.1s linear, background-color 0.1s linear, border 0.1s linear;
}
body * {
  transition: color 0.1s linear, background-color 0.1s linear, background 0.1s linear, border 0.1s linear, fill 0.1s linear;
}

@media screen and (min-width: 576px) {
  .custom-modal .modal-dialog {
    max-width: 566px;
  }
}
@media screen and (min-width: 576px) {
  .custom-modal.custom-modal--big .modal-dialog {
    max-width: 905px;
  }
}
.custom-modal .modal-header {
  justify-content: flex-start;
  align-items: center;
  border: none;
  min-height: 70px;
}
@media screen and (min-width: 768px) {
  .custom-modal .modal-header {
    padding: 30px 30px 0 30px;
  }
}
@media screen and (max-height: 450px) {
  .custom-modal .modal-header {
    padding: 0.75rem 1rem;
    min-height: 50px;
  }
  .custom-modal .modal-header .custom-modal__icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  .custom-modal .modal-header .custom-modal__title {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 768px) {
  .custom-modal .modal-body {
    padding: 30px 77px 30px 77px;
  }
}
.custom-modal .custom-modal__icon {
  color: var(--theme-primary-color);
  width: 32px;
  height: 32px;
  margin-right: 15px;
}
.custom-modal .custom-modal__icon--red {
  color: var(--theme-bp-input-border-warning);
}
@media screen and (max-width: 767px) {
  .custom-modal .custom-modal__icon {
    width: 28px;
    height: 28px;
  }
}
.custom-modal .custom-modal__title {
  font-weight: bold;
  font-size: 1.7rem;
  max-width: calc(100% - 28px - 15px - 40px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 767px) {
  .custom-modal .custom-modal__title {
    font-size: 1.35rem;
  }
}
.custom-modal .custom-modal__btn-box {
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .custom-modal .custom-modal__btn-box {
    width: 100%;
  }
}
.custom-modal .close {
  margin-top: -1.3rem;
}
.custom-modal .custom-modal__btn-margin {
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  .custom-modal .custom-modal__btn-margin {
    margin-top: 20px;
  }
}
@media screen and (max-height: 450px) {
  .custom-modal .custom-modal__btn-margin {
    margin-top: 20px;
  }
}
.custom-modal dd {
  word-wrap: break-word;
}

.custom-spinner {
  width: 1.2rem;
  height: 1.2rem;
  border-width: 0.15rem;
  border-color: var(--theme-primary-color);
  border-right-color: transparent;
}

.without-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.sv-btn-blue, .sv-ui-btn-blue {
  cursor: pointer;
  color: var(--theme-button-colort);
  background-color: var(--theme-primary-color);
  border-color: var(--theme-primary-color);
}
.sv-btn-blue:hover, .sv-ui-btn-blue:hover {
  color: var(--theme-button-colort);
  background-color: var(--theme-primary-color-hover);
}
.sv-btn-blue:focus, .sv-ui-btn-blue:focus {
  color: var(--theme-button-colort);
  background-color: var(--theme-primary-color-hover);
}
.sv-btn-blue:disabled:hover, .sv-ui-btn-blue:disabled:hover {
  background-color: var(--theme-primary-color);
}

.sv-btn-gray, .sv-ui-btn-gray {
  cursor: pointer;
  color: var(--theme-font-tertiary);
  background-color: var(--theme-button);
  border-color: var(--theme-button);
}
.sv-btn-gray:hover, .sv-ui-btn-gray:hover {
  color: var(--theme-primary-color);
  background-color: var(--theme-button-hover);
  border-color: var(--theme-button-hover);
}
.sv-btn-gray:focus, .sv-ui-btn-gray:focus {
  color: var(--theme-primary-color);
  background-color: var(--theme-button-hover);
  border-color: var(--theme-button-hover);
  box-shadow: 0 0 0 0.2rem var(--theme-primary-color-focus);
}
.sv-btn-gray:disabled:hover, .sv-ui-btn-gray:disabled:hover {
  color: var(--theme-font-tertiary);
  background-color: var(--theme-button);
  border-color: var(--theme-button);
}

.sv-btn {
  padding: 0.75rem 3.75rem;
}
.sv-btn .spinner-border {
  margin-right: 0.4rem;
}

.sv-btn-sm {
  padding: 0.75rem 1.75rem;
}
.sv-btn-sm .spinner-border {
  margin-right: 0.2rem;
}

.sv-btn-outline {
  background-color: transparent;
}
.sv-btn-outline.sv-btn-blue, .sv-btn-outline.sv-ui-btn-blue {
  color: var(--theme-primary-color);
}
.sv-btn-outline.sv-btn-blue:hover, .sv-btn-outline.sv-ui-btn-blue:hover {
  color: var(--theme-button-colort);
}

.sv-btn-ui, .sv-ui-btn-gray, .sv-ui-btn-blue {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
}
.sv-btn-ui svg, .sv-ui-btn-gray svg, .sv-ui-btn-blue svg {
  width: 24px;
  height: 24px;
}
@media screen and (max-width: 767px) {
  .sv-btn-ui, .sv-ui-btn-gray, .sv-ui-btn-blue {
    width: 40px;
    height: 40px;
  }
  .sv-btn-ui svg, .sv-ui-btn-gray svg, .sv-ui-btn-blue svg {
    width: 18px;
    height: 18px;
  }
}

.sv-ui-btn-gray--active {
  color: var(--theme-primary-color);
  background-color: var(--theme-button);
  border-color: var(--theme-button);
}

.sv-ui-btn-m + .sv-ui-btn-m {
  margin-left: 1rem;
}
@media screen and (max-width: 767px) {
  .sv-ui-btn-m + .sv-ui-btn-m {
    margin-left: 0.5rem;
  }
}

.Toastify__toast-container .Toastify__toast {
  cursor: default;
}
.Toastify__toast-container .Toastify__toast-body {
  text-align: center;
}
.Toastify__toast-container.Toastify__toast-container--top-center {
  width: 551px;
  max-width: 551px;
  top: 2rem;
}
@media screen and (max-width: 767px) {
  .Toastify__toast-container.Toastify__toast-container--top-center {
    width: 90vw;
    left: 50%;
    transform: translateX(-50%);
    top: 1rem;
  }
}
.Toastify__toast-container.Toastify__toast-container--top-center .Toastify__close-button {
  display: none;
}
.Toastify__toast-container.Toastify__toast-container--top-center .Toastify__toast--info {
  background-color: var(--theme-primary-color);
}
.Toastify__toast-container.Toastify__toast-container--top-center .Toastify__toast {
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
}

.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animate__animated.animate__very-fast {
  -webkit-animation-duration: 0.3333333333s;
  animation-duration: 0.3333333333s;
}

.animate__animated.animate__faster {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

.animate__animated.animate__fast {
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
}

.animate__animated.animate__slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animate__animated.animate__slower {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.animate__fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.animate__fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}