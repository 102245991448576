@import "../../styles/variables/variables";
@import "../../styles/variables/breakpoints";

.top-header {
  height: $header-height;
  margin-bottom: $header-bottom-margin;

  @include breakpoint($lt-md) {
    height: $header-height-mobile;
  }

  .container {
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .top-header__logo-box {
    display: block;
    width: $logo-width;

    img {
      width: $logo-width;
      height: $logo-height;
    }

    @include breakpoint($lt-md) {
      width: $logo-width-mobile;

      img {
        width: $logo-width-mobile;
        height: $logo-height-mobile;
      }
    }
  }

  .top-header__right-box {
    display: flex;
    align-items: center;
  }

  .top-header__profile-box {
    display: flex;
    align-items: center;
  }

  .top-header__profile-name {
    max-width: 230px;
    font-weight: bold;
    font-size: 1.4rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 24px;
    color: var(--theme-font-primary);

    &:hover {
      text-decoration: none;
    }
  }

  .top-header__profile-avatar-box {
    @include breakpoint($lt-lg) {
      margin-left: 10px;
    }

    .top-header__profile-avatar {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      cursor: pointer;
      background-position: center;
      background-image: url("/static/images/profile-img-dark.png");
    }
  }

  &--only-logo .container {
    //div:not(.top-header__logo-box) {
    //  display: none;
    //}

    .top-search,
    .top-header__profile-avatar-box {
      display: none;
    }
  }

  .top-header__black-logo {
    display: none;
  }
}

.light {
  .top-header {
    .top-header__white-logo {
      display: none;
    }

    .top-header__black-logo {
      display: block;
    }

    .top-header__profile-avatar-box .top-header__profile-avatar {
      background-image: url("/static/images/profile-img.png");
    }
  }
}
