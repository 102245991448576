@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.photo-viewer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .photo-viewer__box {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 100%;
    height: 100%;
    max-width: 1024px;
    max-height: 768px;
    transition: all 100ms linear;
    object-fit: contain;
  }
}

.photo-viewer__zoom-box {
  position: absolute;
  height: 46px;
  width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.51);
  border-radius: $border-radius;
  padding: 8px;
  bottom: 5%;

  //@include breakpoint($lt-lg) {
  //  bottom: 50px;
  //}

  .photo-viewer__zoom-icon {
    width: 24px;
    height: 24px;
    background-image: url("/static/icons/zoom.svg");
    background-repeat: no-repeat;
    background-size: cover;
    opacity: .7;
    transition: opacity 100ms linear;
  }

  .photo-viewer__zoom-plus {
    @extend .photo-viewer__zoom-icon;

    cursor: pointer;
    background-image: url("/static/icons/plus.svg");
    &:hover {
      opacity: 1;
    }
  }

  .photo-viewer__zoom-minus {
    @extend .photo-viewer__zoom-icon;

    cursor: pointer;
    background-image: url("/static/icons/minus.svg");

    &:hover {
      opacity: 1;
    }
  }
}
